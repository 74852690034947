import { useEffect, useRef, useState } from "react";

type UseFocusInputOptions = {
  shouldFocus?: () => boolean;
};

export const useFocusInput = ({
  shouldFocus = () => true,
}: UseFocusInputOptions = {}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [lastFocusedIndex, setLastFocusedIndex] = useState<number | null>(null);

  useEffect(() => {
    const setFocus = (index: number | null) => {
      if (shouldFocus() && index !== null) {
        const ref = inputRefs.current[index];
        // Check if the element is visible
        if (ref && ref.offsetParent !== null) {
          ref.focus();
        }
      }
    };

    setFocus(lastFocusedIndex); // Initial focus

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setFocus(lastFocusedIndex);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        !inputRefs.current.some(
          (ref) => ref && ref.contains(event.target as Node)
        )
      ) {
        setFocus(lastFocusedIndex);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [shouldFocus]);

  return (index = 0) =>
    (el: HTMLInputElement | null) => {
      inputRefs.current[index] = el;
      if (el) {
        el.addEventListener("focus", () => setLastFocusedIndex(index));
      }
    };
};
