import React, { useState } from "react";

import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { retrieveClients } from "../proxies/LoginProxy";
import { useFocusInput } from "../hooks/useDefaultFocusInput";

type ClientEntry = {
  clientName: string;
  clientId: string;
};

export const PromptForClientEntry = ({
  onChange,
}: {
  onChange: (clientId: string) => void;
}) => {
  const [clientEntered, setClientEntered] = useState(false);
  const [options, setOptions] = useState([] as ClientEntry[]);
  const setInputRef = useFocusInput();

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    prefix: string
  ) => {
    // eslint-disable-next-line no-void
    void (async (value) => {
      try {
        await retrieveClients(value.trim()).then((response) => {
          setOptions(response as unknown as ClientEntry[]);
        });
      } catch (error) {
        // Handle any errors here
      }
    })(prefix);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="body2">
        Your account is not associated with a business
      </Typography>
      <Typography variant="body2">Choose an organization below.</Typography>

      <Autocomplete
        autoFocus
        options={options}
        getOptionKey={(option) => option.clientId}
        getOptionLabel={(option) => option.clientName}
        onInputChange={handleInputChange}
        onChange={(_event, value) => {
          // 'value' is the selected option from the options array
          // You can now use 'value.clientId' or 'value.clientName' depending on your needs
          if (value) {
            setClientEntered(true);
            onChange(value.clientId);
          } else {
            setClientEntered(false);
            onChange("");
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!clientEntered}
            helperText={
              !clientEntered ? "Start typing to select an organization" : ""
            }
            label="Organization"
            inputRef={setInputRef()}
          />
        )}
      />
    </Stack>
  );
};
