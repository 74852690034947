import React, { useState } from "react";
import {
  Box,
  Card,
  ClickAwayListener,
  Link,
  Stack,
  Typography,
} from "@mui/material";

export const FooterHelp = () => {
  const [open, setOpen] = useState(false);

  const toggleHelp = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Link variant="caption" href="#" onClick={toggleHelp}>
          Need assistance?
        </Link>
        <Box visibility={open ? "visible" : "hidden"}>
          <Card variant="outlined">
            <Stack spacing={2}>
              <Typography variant="body1">We&apos;re here to help</Typography>
              <Link variant="body1" href="mailto:support@ecisolutions.com">
                support@ecisolutions.com
              </Link>
              <Link variant="body1" href="tel:1.855.400.4564">
                1.855.400.4564
              </Link>
              <Link
                variant="body1"
                href="http://customerportal.ecisolutions.com"
              >
                http://customerportal.ecisolutions.com
              </Link>
              <div>
                <Typography>M - F</Typography>
                <Typography>8:30am - 8:00pm EST</Typography>
                <Typography>5:30am - 5:30pm PST</Typography>
              </div>
            </Stack>
          </Card>
        </Box>
      </div>
    </ClickAwayListener>
  );
};
