export enum TokenType {
  Lasso = "Lasso",
  Nexus = "Nexus",
}

export type LassoState = {
  userName: string;
  loginType: TokenType;
  loginSessionExpiry: number;
  autoLogin: boolean;
  loginUrl: string;
  clientId: number | null;
};

export type PasswordFormData = {
  password: string;
} & Pick<LassoState, "userName">;

export const EMPTY_STATE: LassoState = {
  userName: "",
  loginType: TokenType.Lasso,
  loginSessionExpiry: 0,
  autoLogin: true,
  loginUrl: "/password",
  clientId: null,
};

export type ConvertUserFormData = Pick<LassoState, "userName">;
export const lassoSecretConvertUserFormData = (
  secret: LassoState
): ConvertUserFormData => {
  return {
    userName: secret.userName,
  };
};

// export type BearerToken = {  TODO may need after nexus integration
//   userId: string;
//   userName: string;
//   loginType: string;
//   nexusId: string;
//   nexusSid: string;
//   loginUrl: string;
//   exp: number;
// };

export const isTokenExpired = (token: { exp: number } | null): boolean => {
  if (token) {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    return currentTimeInSeconds > token.exp;
  }
  return true;
};
