export default class LassoNewsItem {
  messageID: number;

  body: string;

  location: string;

  importance: number;

  constructor(
    messageID: number,
    body: string,
    location: string,
    importance = 3
  ) {
    this.messageID = messageID;
    this.body = body;
    this.location = location;
    this.importance = importance;
  }

  static fromJson(item: {
    messageID: number;
    body: string;
    location: string;
    importance: number;
  }) {
    return new LassoNewsItem(
      item.messageID,
      item.body,
      item.location,
      item.importance
    );
  }
}
