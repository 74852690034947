import React, { useEffect, useState } from "react";
import { CircularProgress, Container } from "@mui/material";
import parse from "html-react-parser";
import { isAvailable } from "../proxies/LoginProxy";
import { StyledAlert } from "../ui/StyledControls";

const lines = (str: string) => parse(str.split("\n").join("<br />"));

export const MaintenanceCheck = ({
  children,
}: {
  children: React.JSX.Element;
}): React.JSX.Element => {
  const [state, setState] = useState({
    loading: true,
    available: false,
    message: "",
  });

  useEffect(() => {
    isAvailable()
      .then((r) => {
        setState({
          loading: false,
          available: r.available,
          message: r.message ? r.message : "",
        });
      })
      .catch((e: Error) => {
        setState({
          loading: false,
          available: false,
          message: e.message,
        });
      });
  }, []);

  if (state.loading) {
    return (
      <Container style={{ marginTop: 10 }}>
        <CircularProgress variant="indeterminate" />
      </Container>
    );
  }
  if (state.available) {
    return children;
  }
  return <StyledAlert severity="error">{lines(state.message)}</StyledAlert>;
};
