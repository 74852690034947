import React, { useState, useEffect } from "react";
import { AlertColor, Button, Grid, Stack } from "@mui/material";

import { resetPassword } from "../proxies/LoginProxy";
import { usePasswordValidator } from "../hooks/usePasswordValidator";
import { useJwtDecoder } from "../hooks/useJwtDecoder";

import { FormTitle, StyledAlert } from "../ui/StyledControls";
import { PasswordEntry } from "../ui/PasswordEntry";
import { isTokenExpired } from "../models/LassoState";
import { useFocusInput } from "../hooks/useDefaultFocusInput";

export const ResetPasswordForm = ({
  loginToken,
  navigator,
}: {
  loginToken: string | null;
  navigator: (url: string) => void;
}) => {
  const [success, setSuccess] = useState(false);
  const noAlert = { msg: "", severity: "info" as AlertColor };
  const [alert, setAlert] = useState(noAlert);
  const [formData, setFormData] = useState({
    userName: "", // automaticUsername,
    temporaryPassword: "", // automaticAuthCode,
    password: "",
    confirmPassword: "",
  });
  const [decodeToken] = useJwtDecoder();
  const setInputRef = useFocusInput();

  useEffect(() => {
    // Extract the userName and temporary password from the decoded token
    type LoginCookieToken = {
      userName: string;
      temporaryPassword: string;
      exp: number;
    };

    // Decode the JWT token
    const token = decodeToken(loginToken) as LoginCookieToken;

    if (token) {
      if (isTokenExpired(token)) {
        setAlert({
          msg: "Password reset token has expired, please request a new password reset",
          severity: "error" as AlertColor,
        });
        return;
      }

      // Update the form data with the extracted values
      setFormData((previousFormData) => ({
        ...previousFormData,
        userName: token.userName,
        temporaryPassword: token.temporaryPassword,
      }));
    } else {
      setAlert({
        msg: "Password reset token has expired, please request a new password reset",
        severity: "error" as AlertColor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const passwordValid = usePasswordValidator({
    password: formData.password,
  });

  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValid(passwordValid);
  }, [formData.password, passwordValid]);

  const clearAlert = () => setAlert(noAlert);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    clearAlert();
    if (success) {
      navigator("/");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resetPassword(loginToken!, {
      userName: formData.userName,
      temporaryPassword: formData.temporaryPassword,
      newPassword: formData.password,
    })
      .then(() => {
        setSuccess(true);
        setAlert({
          msg: "Password successfully changed, please click <b>Continue</b> to re-login.",
          severity: "success",
        });
      })
      .catch(() =>
        setAlert({
          msg: "Unable to reset the password, Please contact support",
          severity: "error",
        })
      );
  };

  return (
    <form onSubmit={submitHandler}>
      <Stack spacing={3}>
        <FormTitle>Reset your password</FormTitle>
        <label>Enter new password for {formData.userName} </label>
        <input hidden name="userName" value={formData.userName} />
        <input
          hidden
          name="temporaryPassword"
          value={formData.temporaryPassword}
        />
        <PasswordEntry
          onValidPassword={(password: string) => {
            setFormData({
              ...formData,
              password,
            });
          }}
          onInvalidPassword={(password: string) => {
            setFormData({
              ...formData,
              password,
            });
          }}
          inputRef={setInputRef()}
          confirmRef={setInputRef(1)}
        />
        <Grid container>
          <Grid item xs={8}>
            <Button
              fullWidth
              disabled={!valid}
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              color="success"
              onClick={() => {
                navigator("/forgot-password");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <div hidden={alert.msg === ""}>
          <StyledAlert severity={alert.severity} onClose={clearAlert}>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: alert.msg }} />
          </StyledAlert>
        </div>
      </Stack>
    </form>
  );
};
