import React from "react";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import App from "./app/App";
import { APP_ROOT } from "./app/config";

const root = document.getElementById("root") as HTMLElement;
render(
  <React.StrictMode>
    <BrowserRouter basename={APP_ROOT}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  root
);
