import React, { useEffect } from "react";
import { CircularProgress, Container } from "@mui/material";

export const Landing = () => {
  useEffect(() => {
    window.location.replace("/index.php");
  }, []);

  return (
    <Container style={{ textAlign: "center", padding: "2dvw" }}>
      <CircularProgress variant="indeterminate" />
    </Container>
  );
};
