/** This will match the PostData type in models/PostData.ts */
export type ResetPasswordRequestData = {
  userName: string;
  newPassword: string;
  temporaryPassword: string;
};

export type UserNameRequestData = {
  userName: string;
};

export type RestPasswordRequestData = {
  userName: string;
};

export type LoginRequestData = {
  userName: string;
  password: string;
  timezone: string;
};

export type UpdateEmailPasswordRequestData = {
  userId: number;
  userName: string;
  email: string;
  password: string;
  clientId: number;
  showPassword: boolean;
  showClient: boolean;
  showEmail: boolean;
};

export const DEFAULT_EMAIL_PASSWORD_REQUEST = {
  userId: -1,
  userName: "",
  password: "",
  email: "",
  clientId: -1,
  showPassword: true,
  showClient: true,
  showEmail: true,
} as UpdateEmailPasswordRequestData;

export type ConversionRequestData = {
  userName: string;
  nexusId: string;
  password: string;
  email: string;
  showPassword: boolean;
};
export const DEFAULT_CONVERSION_REQUEST = {
  userName: "",
  nexusId: "",
  password: "",
  email: "",
  showPassword: true,
} as ConversionRequestData;
