import { styled } from "@mui/material/styles";
import { Alert, Typography } from "@mui/material";
import { theme } from "../themes/themes";

export const FormTitle = styled(Typography)({
  color: "#333",
  marginBottom: theme.spacing(1),
  fontSize: theme.spacing(3),
});

export const StyledAlert = styled(Alert)({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
});

export const StyledDropDown = styled("div")({
  position: "absolute",
  top: "50%",
  width: "max-content",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 10,
  border: "1px solid",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
});

export const StyledNewsItem = styled("div")({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
});

export const StyledNewsHolder = styled("div")({
  width: "fit-content",
  overflow: "hidden",
});
