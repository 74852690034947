import { DEFAULT_DOWNTIME_MESSAGE, LOGIN_EXPIRED_MESSAGE } from "../app/config";
import LassoNewsItem from "../models/LassoNewsItem";
import { LassoState } from "../models/LassoState";
import {
  LoginRequestData,
  ResetPasswordRequestData,
  UpdateEmailPasswordRequestData,
  UserNameRequestData,
  RestPasswordRequestData,
} from "../models/PostRequests";
import { PostData } from "../models/PostData";
import { SuccessFullLoginResponse } from "../models/LoginResponse";

const isJson = (res: Response) =>
  res.headers.get("content-type") === "application/json";
const isSuccess = (res: Response) => res.status >= 200 && res.status < 300;

export const readResponse = async <T>(res: Response): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    // suppress internal error messages from popping up as alerts
    if (res.status === 503) {
      reject(DEFAULT_DOWNTIME_MESSAGE);
    } else if (res.status === 401) {
      res
        .text()
        .then((msg) => {
          const message = msg.replace(/^"|"$/g, "");
          if (message !== "Unauthorized") {
            reject(message);
          } else {
            reject(LOGIN_EXPIRED_MESSAGE);
          }
        })
        .catch((e: Error) => reject(e.message));
    } else if (isSuccess(res)) {
      (isJson(res) ? (res.json() as Promise<unknown>) : res.text())
        .then((data: unknown | string) => resolve(data as T))
        .catch((e: Error) => reject(e.message));
    } else {
      res
        .text()
        .then((data: string) => reject(Error(data)))
        .catch((e: Error) => reject(e.message));
    }
  });
};

type Headers = {
  [key: string]: string;
};

const headers = { "Content-Type": "application/json" } as Headers;

export const API_ROOT = "/auth";

const post = (
  path: string,
  payload: PostData,
  hdrs = headers
): Promise<Response> => {
  return fetch(`${API_ROOT}/${path}`, {
    method: "POST",
    body: payload && JSON.stringify(payload),
    headers: hdrs,
  });
};

export const isAvailable = async (): Promise<{
  available: boolean;
  message?: string;
}> => {
  try {
    const response = await fetch(`${API_ROOT}/isAvailable`);
    if (!isSuccess(response)) {
      return {
        available: false,
        message:
          response.status === 503
            ? await response.text()
            : DEFAULT_DOWNTIME_MESSAGE,
      };
    }
    return {
      available: true,
    };
  } catch (e) {
    return {
      available: false,
      message: DEFAULT_DOWNTIME_MESSAGE,
    };
  }
};

async function retrieveNews(location: string): Promise<LassoNewsItem[]> {
  const response = await fetch(`${API_ROOT}/news/${location}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = (await response.json()) as {
    messageID: number;
    body: string;
    location: string;
    importance: number;
  }[];
  return data.map((item) => LassoNewsItem.fromJson(item));
}

export const retrieveClients = async (prefix: string) => {
  const response = await fetch(`${API_ROOT}/clients?search=${prefix}`, {
    method: "GET",
    headers: {
      ...headers,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return (await response.json()) as {
    clientId: number;
    clientName: string;
  }[];
};

export const loginHelp = async () => retrieveNews("login-help");
export const getLoginNews = async () => retrieveNews("login-right");

export const loginRouter = (data: UserNameRequestData): Promise<LassoState> =>
  post("login-router", data).then((res: Response) => {
    return readResponse<LassoState>(res);
  });

export const autoLogin = (): Promise<Response> =>
  post("auto-login", { "": "" }, headers);

export const login = (data: LoginRequestData): Promise<Response> => {
  return post("login", data, {
    ...headers,
  });
};

export const requestPasswordReset = async (postData: RestPasswordRequestData) =>
  post("requestPasswordReset", postData, {
    ...headers,
  }).then(async (response) => {
    return readResponse<string>(response);
  });

export const resetPassword = async (
  authToken: string,
  postData: ResetPasswordRequestData
) =>
  post("resetPassword", postData, {
    ...headers,
  }).then(async (response) => {
    return readResponse<void>(response);
  });

export const attemptUpdate = (data: UpdateEmailPasswordRequestData) =>
  post("update", data, {
    ...headers,
  }).then((res: Response) => {
    return readResponse<SuccessFullLoginResponse>(res);
  });
