import { useState, useEffect } from "react";

// Type for the hook's return value
type UseSessionStorageReturn<T> = [
  T | undefined,
  (value: T | undefined) => void
];

/**
 * Custom React hook to manage session storage.
 * @param key The key to use for storing and retrieving the value.
 * @param defaultValue The default value to use if the key does not exist in session storage.
 */
function useSessionStorage<T>(
  key: string,
  defaultValue: T | undefined = undefined
): UseSessionStorageReturn<T> {
  // State to store the value from session storage
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return (item ? JSON.parse(item) : defaultValue) as T;
    } catch (_) {
      return defaultValue;
    }
  });

  // Function to update the value in session storage
  const setValue = (value: T | undefined) => {
    if (value === undefined) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
    setStoredValue(value);
  };

  // Effect to synchronize the state with session storage
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key === key) {
        const newValue = e.newValue ? (JSON.parse(e.newValue) as T) : undefined;
        setStoredValue(newValue);
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [key]);

  return [storedValue, setValue];
}

export default useSessionStorage;
