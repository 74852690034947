import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Grid, Card, CardContent } from "@mui/material";
import { getLoginNews } from "../proxies/LoginProxy";
import { StyledNewsHolder, StyledNewsItem } from "../ui/StyledControls";
import LassoNewsItem from "../models/LassoNewsItem";

const NewsEntries = (params: { items: LassoNewsItem[] }) => {
  const { items } = params;
  if (items && items.length === 0) {
    return (
      <Card style={{ padding: "1dvw", margin: "1dvw" }}>
        <CardContent>
          <h1>Lasso CRM for Home builders and Real Estate Developers</h1>
          <p style={{ lineClamp: 3 }}>
            Captures and tracks online leads from various sources, automates
            follow-ups, and provides targeted marketing capabilities.
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {items.map(
        (item: LassoNewsItem): React.JSX.Element => (
          <StyledNewsItem key={`news_${item.messageID}`}>
            {parse(item.body)}
          </StyledNewsItem>
        )
      )}
    </>
  );
};

export const LoginNews = ({ showNews }) => {
  const [content, setContent] = useState([] as LassoNewsItem[]);

  useEffect(() => {
    if (showNews) {
      getLoginNews()
        .then((items) => setContent(items))
        .catch(() => {
          setContent([] as LassoNewsItem[]);
        });
    }
  }, [showNews, setContent]);

  return (
    <Grid>
      <StyledNewsHolder>
        <NewsEntries items={content} />
      </StyledNewsHolder>
    </Grid>
  );
};
