import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { red, green } from "@mui/material/colors";
import {
  CheckCircle,
  Cancel,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { usePasswordValidator } from "../hooks/usePasswordValidator";

const GreenCircle = () => <CheckCircle style={{ color: green[400] }} />;
const RedCross = () => <Cancel style={{ color: red[400] }} />;
const PasswordValidation = (props: {
  clauseText: string;
  isValid: boolean;
}) => {
  const { clauseText, isValid } = props;
  return (
    <Grid container spacing={1}>
      <Grid item>{isValid ? <GreenCircle /> : <RedCross />}</Grid>
      <Grid item>
        <Typography variant="caption">{clauseText}</Typography>
      </Grid>
    </Grid>
  );
};

export const PasswordEntry = ({
  onValidPassword,
  onInvalidPassword,
  inputRef,
  confirmRef,
}: {
  onValidPassword: (password: string) => void;
  onInvalidPassword: (password: string) => void;
  inputRef: (el: HTMLInputElement | null) => void;
  confirmRef: (el: HTMLInputElement | null) => void;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    atLeastEightChars: false,
    oneUppercaseLetter: false,
    oneLowercaseLetter: false,
    oneSpecialChar: false,
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const passwordValid = usePasswordValidator(passwords);

  useEffect(() => {
    if (passwordValid && passwords.password === passwords.confirmPassword) {
      onValidPassword(passwords.password);
    } else {
      onInvalidPassword(passwords.password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwords, passwordValid]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const disableMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as { name: string; value: string };
    setPasswords({
      ...passwords,
      [name]: value,
    });

    if (name === "password") {
      setPasswordValidation({
        atLeastEightChars: value.length >= 8,
        oneUppercaseLetter: /[A-Z]/.test(value),
        oneLowercaseLetter: /[a-z]/.test(value),
        // eslint-disable-next-line no-useless-escape
        oneSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':" \\|,.<>\/?]/.test(value),
      });
    }
  };

  const confirmPasswordError =
    !passwordValid ||
    passwords.confirmPassword === "" ||
    passwords.password !== passwords.confirmPassword;

  const confirmPasswordMsg =
    passwordValid && passwords.password !== passwords.confirmPassword
      ? "Passwords must match"
      : "";

  return (
    <Stack>
      <TextField
        fullWidth
        autoFocus
        inputRef={inputRef}
        required
        label="New Password"
        name="password"
        size="small"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        onChange={handleChange}
        error={confirmPasswordError}
        value={passwords.password}
        InputProps={{
          autoComplete: "new-password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                onMouseDown={disableMouseDown}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Stack padding={2}>
        <PasswordValidation
          clauseText="Minimum eight characters"
          isValid={passwordValidation.atLeastEightChars}
        />
        <PasswordValidation
          clauseText="Contains at least one uppercase letter"
          isValid={passwordValidation.oneUppercaseLetter}
        />
        <PasswordValidation
          clauseText="Contains at least one lowercase letter"
          isValid={passwordValidation.oneLowercaseLetter}
        />
        <PasswordValidation
          clauseText="Contains at least one special character"
          isValid={passwordValidation.oneSpecialChar}
        />
      </Stack>
      <TextField
        fullWidth
        inputRef={confirmRef}
        label="Confirm Password"
        name="confirmPassword"
        size="small"
        type={showConfirm ? "text" : "password"}
        variant="outlined"
        required
        InputProps={{
          autoComplete: "confirm-password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowConfirm}
                onMouseDown={disableMouseDown}
              >
                {showConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        error={confirmPasswordError}
        helperText={confirmPasswordMsg}
        value={passwords.confirmPassword}
      />
    </Stack>
  );
};
