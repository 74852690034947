import React from "react";

import { Stack, Typography } from "@mui/material";
import { PasswordEntry } from "./PasswordEntry";
import { useFocusInput } from "../hooks/useDefaultFocusInput";

export const PromptForNewPassword = ({
  onValidPassword,
  onInvalidPassword,
}: {
  onValidPassword: (password: string) => void;
  onInvalidPassword: (password: string) => void;
}) => {
  const setInputRef = useFocusInput();
  return (
    <Stack spacing={2}>
      <Typography variant="body2">
        Enter a new password which meets the below requirements.
      </Typography>
      <PasswordEntry
        onValidPassword={onValidPassword}
        onInvalidPassword={onInvalidPassword}
        inputRef={setInputRef()}
        confirmRef={setInputRef(1)}
      />
    </Stack>
  );
};
