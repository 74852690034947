import { saveState } from "../app/storage";
import { TokenType } from "../models/LassoState";
import { loginRouter } from "../proxies/LoginProxy";

export function useLoginRouter(
  navigator: (url: string) => void,
  onLoaded: () => void,
  onError: (msg: string) => void
) {
  const invokeLoginRouter = (userName: string) => {
    loginRouter({ userName })
      .then(async (secret) => {
        onLoaded();
        await saveState(secret);
        if (secret.loginType === TokenType.Lasso) {
          navigator(secret.loginUrl);
        } else {
          // TODO need to handle this once conversion is complete
          // const logoutHint =
          //   token && token.nexusSid ? `&logout_hint=${token.nexusSid}` : "";
          window.location.replace(
            `${secret.loginUrl}&login_hint=${secret.userName}`
          );
        }
      })
      .catch((reason: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        onError(reason.toString());
      });
  };

  return [invokeLoginRouter];
}
