import { useEffect, useRef, useState } from "react";
import { autoLogin, readResponse } from "../proxies/LoginProxy";
import { AutoLoginResponse, LoginResponse } from "../models/LoginResponse";
import {
  isPromptForEmail,
  isSuccessFullLoginResponse,
} from "../pages/PasswordEntryForm";
import { loadLassoState, saveState } from "../app/storage";
import { LassoState, TokenType } from "../models/LassoState";
/**
 * React hook to do attempt the auto login
 *
 * This registers the auto login hook. The useEffect is disabled when the page is mounted.
 * This prevents a continous logout/login loop
 *
 * @param navigator   React navigator for internal routing
 * @param onLoaded    Callback to set the loading state
 *
 * @return an array of exposes functions to manage the hook state.
 *         currently there is only one 'triggerAutoLogin' to trigger the hook
 */
export function useAutoLogin(
  navigator: (url: string) => void,
  onLoaded: () => void
) {
  const [triggered, setTrigger] = useState(false);
  const hasMounted = useRef(false);

  useEffect(() => {
    const tryAutoLogin = (): Promise<AutoLoginResponse> => {
      return new Promise((resolve) => {
        autoLogin()
          .then((response: Response) => {
            if (!response.redirected) {
              readResponse<LoginResponse>(response)
                .then((data) => {
                  onLoaded();
                  if (isSuccessFullLoginResponse(data)) {
                    resolve({ status: "redirected", url: data.landing });
                  } else if (isPromptForEmail(data)) {
                    resolve({ status: "redirected", url: data.internal });
                  } else {
                    // if the auto login failed, clear the state and try again
                    resolve({ status: "error", message: data });
                  }
                })
                .catch((e: Error) => {
                  resolve({ status: "error", message: e.message });
                });
            } else {
              resolve({ status: "redirected", url: response.url });
            }
          })
          .catch(() => {
            resolve({ status: "error", message: "Network error" });
          });
      });
    };

    const autoLoginHandler = (lassoSecret: LassoState) => {
      tryAutoLogin()
        .then((response) => {
          if (response.status === "error") {
            if (lassoSecret.loginType === TokenType.Nexus) {
              window.location.replace(lassoSecret.loginUrl);
            } else {
              navigator(lassoSecret.loginUrl);
            }
          } else {
            window.location.replace(response.url as string);
          }
        })
        .catch(async () => {
          await saveState({
            ...lassoSecret,
            autoLogin: false,
          });
          // resetState();
          navigator(lassoSecret.loginUrl);
        });
    };

    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    if (triggered) {
      loadLassoState()
        .then((secret) => {
          autoLoginHandler(secret);
          setTrigger(false);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggered]);

  // Function to trigger the auto-login process
  const triggerAutoLogin = () => {
    setTrigger(true);
  };

  return [triggerAutoLogin];
}
