import React from "react";
import { Stack, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { FooterHelp } from "./FooterHelp";
import { API_ROOT } from "../proxies/LoginProxy";

type LayoutProps = {
  navigator: (location: string) => void;
  children: React.ReactNode;
  news: React.ReactNode;
};

type SmallScreenLayoutProps = Pick<LayoutProps, "navigator" | "children">;

const SmallScreenLayout = (props: SmallScreenLayoutProps) => {
  const { navigator, children } = props;
  return (
    <Container
      sx={{
        maxWidth: {
          xs: "90dvw",
          sm: "55dvw",
        },
        paddingTop: 10,
      }}
    >
      <Stack spacing={3}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigator("/");
          }}
          style={{ textAlign: "center" }}
        >
          <img
            src={`${API_ROOT}/lasso_logo.png`}
            alt="Lasso CRM Logo"
            width="250px"
          />
        </a>
        {children}
        <FooterHelp />
      </Stack>
    </Container>
  );
};

const MediumScreenLayout = (props: LayoutProps) => {
  const { news, children, navigator } = props;
  return (
    <Grid style={{ marginTop: "15dvh" }} container>
      <Grid item xs={6}>
        {news}
      </Grid>
      <Grid item xs={6}>
        <Container>
          <Stack>
            <a
              href="#"
              style={{ textAlign: "center", paddingBottom: "2dvh" }}
              onClick={() => {
                navigator("/");
              }}
            >
              <img
                src={`${API_ROOT}/lasso_logo.png`}
                alt="eci logo"
                width="250px"
              />
            </a>
          </Stack>
          {children}
          <FooterHelp />
        </Container>
      </Grid>
    </Grid>
  );
};

const Layout = (props: LayoutProps) => {
  const { children, news, navigator } = props;
  const theme = useTheme();
  const isSmallLayout = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumToLarge = useMediaQuery(theme.breakpoints.only("md"));

  if (isSmallLayout) {
    return (
      <SmallScreenLayout navigator={navigator}>{children} </SmallScreenLayout>
    );
  }

  if (isMediumToLarge) {
    return (
      <MediumScreenLayout navigator={navigator} news={news}>
        {children}
      </MediumScreenLayout>
    );
  }

  return (
    <Container
      style={{
        maxWidth: "73dvw",
        paddingTop: "15dvh",
      }}
    >
      <Grid container>
        <Grid item xs={7}>
          {news}
        </Grid>
        <Grid item xs={5}>
          <Stack>
            <a
              href="#"
              style={{ textAlign: "center", paddingBottom: "2dvh" }}
              onClick={() => {
                navigator("/");
              }}
            >
              <img
                src={`${API_ROOT}/lasso_logo.png`}
                alt="eci logo"
                width="250px"
              />
            </a>
          </Stack>

          {children}
          <FooterHelp />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Layout;
