import { Hidden, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { UpdateEmailPasswordRequestData } from "../models/PostRequests";
import { useFocusInput } from "../hooks/useDefaultFocusInput";

export const PromptForEmailEntry = ({
  formData,
  handleChange,
}: {
  formData: UpdateEmailPasswordRequestData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const setInputRef = useFocusInput();
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="body2">
          Enter an email to use with <b>{formData.userName}</b> which will be
          used as an alternative username.
        </Typography>
        <Typography variant="body2">
          Email should not be a shared email address.
        </Typography>
      </Stack>
      <Hidden implementation="js" xsUp>
        <input type="text" name="userId" value={formData.userId} readOnly />
      </Hidden>
      <TextField
        fullWidth
        autoFocus
        inputRef={setInputRef()}
        label="Email"
        name="email"
        size="small"
        variant="outlined"
        required
        onChange={handleChange}
        error={formData.email === ""}
        helperText={formData.email === "" ? "Email is required" : ""}
        value={formData.email || ""}
        InputProps={{
          autoComplete: "username",
        }}
      />
    </Stack>
  );
};
