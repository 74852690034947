import { COMMON_PASSWORDS } from "../generatedcommon-passwords";

const commonPasswords: Set<string> = new Set(COMMON_PASSWORDS);

const checkCommonPasswords = (password = ""): boolean => {
  return !commonPasswords.has(password);
};

/**
 * Nexus password requirements:
 * Passwords must be at least 8 characters, container a lowercase and uppercase character, contain a
 * special character, cannot contain personal information such as name or email, and cannot be on the
 * public list of the 10,000 most common passwords published at
 * https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10k-most-common.txt
 */
export const usePasswordValidator = ({ password = "" }) =>
  [
    password.length >= 8,
    /[a-z]/.test(password) && /[A-Z]/.test(password),
    /[^a-zA-Z0-9]/.test(password),
    /[!@#$%^&*()_+\-=[\]{};':" \\|,.<>/?]/.test(password),
    checkCommonPasswords(password),
  ].every(Boolean);
