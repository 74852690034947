import { EncryptStorage } from "encrypt-storage";
import { EMPTY_STATE, LassoState } from "../models/LassoState";

let encryptedStorage: EncryptStorage | null = null;

interface SecretKeyResponse {
  secretKey: string;
}

type EncryptedStorageItemType = string | null | undefined | object;

const KEY_URL = "/auth/key";
const getSecretKey = async (): Promise<string> => {
  const response = await fetch(KEY_URL);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = (await response.json()) as SecretKeyResponse;
  return data.secretKey;
};

const initializeStorage = async (): Promise<void> => {
  if (encryptedStorage === null) {
    const secretKey: string = await getSecretKey();
    encryptedStorage = new EncryptStorage(secretKey);
  }
};

export const LASSO_LOCAL_STORAGE = "lasso-local-state";

export const loadLassoState = async (): Promise<LassoState> => {
  await initializeStorage();
  const json: EncryptedStorageItemType = await encryptedStorage?.getItem(
    LASSO_LOCAL_STORAGE
  );
  return json ? (JSON.parse(JSON.stringify(json)) as LassoState) : EMPTY_STATE;
};

export const saveState = async (lassoState: LassoState): Promise<void> => {
  await initializeStorage();
  encryptedStorage!.setItem(LASSO_LOCAL_STORAGE, JSON.stringify(lassoState));
};

export const clearState = async (): Promise<void> => {
  await initializeStorage();
  encryptedStorage!.removeItem(LASSO_LOCAL_STORAGE);
};
