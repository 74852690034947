import React from "react";
import { Button, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useSessionStorage from "../hooks/useSessionStorage";
import { StyledAlert } from "../ui/StyledControls";
import { FooterHelp } from "../layout/FooterHelp";

export const NotFound = ({
  navigator,
}: {
  navigator: (url: string | undefined) => void;
}) => {
  const [currentLocation] = useSessionStorage<string>("currentLocation");

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button startIcon={<ArrowBackIcon />} onClick={() => navigator("/")}>
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <StyledAlert severity="error" onClose={() => navigator("/")}>
          Unexpected navigation encountered (`{currentLocation}`). Press the{" "}
          <b>BACK</b> button or close this error to continue.
        </StyledAlert>
      </Grid>
      <Grid item xs={12}>
        <FooterHelp />
      </Grid>
    </Grid>
  );
};
