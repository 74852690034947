import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Layout from "../layout/Layout";
import { MaintenanceCheck } from "./MaintenanceCheck";
import { UserEntryForm } from "../pages/UserEntryForm";
import { theme } from "../themes/themes";
import { PasswordEntryForm } from "../pages/PasswordEntryForm";
import { ForgotPasswordForm } from "../pages/ForgotPasswordForm";
import { ResetPasswordForm } from "../pages/ResetPasswordForm";
import { ThankYouPage } from "../pages/ThankYou";
import { PromptForEmailAndPasswordForm } from "../pages/PromptForEmailAndPasswordForm";

import { loadLassoState } from "./storage";

import useSessionStorage from "../hooks/useSessionStorage";
import { Landing } from "../pages/Landing";
import { NotFound } from "../pages/NotFound";
import { LoginNews } from "../layout/LoginNews";

const App = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [loginToken, setLoginToken] = React.useState<string | null>(null);
  const [currentLocation, setCurrentLocation] =
    useSessionStorage<string>("currentLocation");

  // Navigate to the saved location from session storage when the component mounts
  useEffect(() => {
    const page = searchParams.get("page");
    if (page !== null) {
      const queryParams = new URLSearchParams(window.location.search);
      setLoginToken(queryParams.get("token"));
      setCurrentLocation(`/${page}`);
      window.history.replaceState(
        {},
        "",
        `${window.location.origin}${window.location.pathname}`
      );
    } else {
      // eslint-disable-next-line no-void
      void loadLassoState().then((secret) => {
        if (secret.loginSessionExpiry < new Date().getTime()) {
          setCurrentLocation("/");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MaintenanceCheck>
        <Layout navigator={setCurrentLocation} news={<LoginNews showNews />}>
          <Routes location={currentLocation || location}>
            <Route
              path="/"
              element={<UserEntryForm navigator={setCurrentLocation} />}
            />
            <Route
              path="/password"
              element={<PasswordEntryForm navigator={setCurrentLocation} />}
            />
            <Route
              path="/forgot-password"
              element={<ForgotPasswordForm navigator={setCurrentLocation} />}
            />
            <Route
              path="/reset-password"
              element={
                <ResetPasswordForm
                  loginToken={loginToken}
                  navigator={setCurrentLocation}
                />
              }
            />
            <Route
              path="/thank-you"
              element={<ThankYouPage navigator={setCurrentLocation} />}
            />
            <Route
              path="/promptEmailAndPassword"
              element={
                <PromptForEmailAndPasswordForm navigator={setCurrentLocation} />
              }
            />
            <Route path="/landing" element={<Landing />} />
            <Route
              path="*"
              element={<NotFound navigator={setCurrentLocation} />}
            />
          </Routes>
        </Layout>
      </MaintenanceCheck>
    </ThemeProvider>
  );
};

export default App;
