import { useCallback } from "react";
import { jwtDecode, JwtPayload } from "jwt-decode";

export type UseJwtDecoderType = [(token: string | null) => JwtPayload | null];

/**
 * React hook to encapsulate accessing the contents of a JWT token
 * @param initialToken The initial token or null
 *
 * @return An array of state management functions:
 * <ol>
 *   <li>decodedToken   - get the decoded token</li>
 *   <li>setToken       - set the token and attempt to decode it</li>
 *   <li>isTokenExpired - Check that the token hasn't expired</li>
 * </ol>
 */
export const useJwtDecoder = (): UseJwtDecoderType => {
  // Wrap setToken in useCallback to prevent it from being recreated on every render
  const decodeToken = useCallback((token: string | null): JwtPayload | null => {
    return token ? jwtDecode(token) : null;
  }, []); // Dependencies array is empty because setToken does not depend on any external values

  return [decodeToken];
};
