import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useSessionStorage from "../hooks/useSessionStorage";

import { StyledAlert } from "../ui/StyledControls";
import {
  DEFAULT_EMAIL_PASSWORD_REQUEST,
  UpdateEmailPasswordRequestData,
} from "../models/PostRequests";
import { attemptUpdate } from "../proxies/LoginProxy";
import { PromptForNewPassword } from "../ui/PromptForNewPassword";
import { PromptForEmailEntry } from "../ui/PromptForEmailEntry";
import { PromptForClientEntry } from "../ui/PromptForClientEntry";

const ENTER_CLIENT_LABEL = "Associate with organization";
const CONFIRM_EMAIL_LABEL = "Confirm email";
const ENTER_SECURE_PASSWORD_LABEL = "Enter a new password";

export const PromptForEmailAndPasswordForm = ({
  navigator,
}: {
  navigator: (url: string | undefined) => void;
}) => {
  const noAlert = { msg: "", severity: "info" as AlertColor };
  const [alert, setAlert] = useState(noAlert);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [formData, setFormData] = useState(
    {} as UpdateEmailPasswordRequestData
  );
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = [
    formData.showClient && ENTER_CLIENT_LABEL,
    formData.showEmail && CONFIRM_EMAIL_LABEL,
    formData.showPassword && ENTER_SECURE_PASSWORD_LABEL,
  ].filter(Boolean);

  const [conversionData] = useSessionStorage(
    "emailData",
    DEFAULT_EMAIL_PASSWORD_REQUEST
  );

  useEffect(() => {
    setFormData(conversionData || DEFAULT_EMAIL_PASSWORD_REQUEST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFormData = (name: string, newValue: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData("email", event.target.value.trim());
  };

  const isValidFormData = (data: UpdateEmailPasswordRequestData): boolean => {
    return (
      (formData.showClient &&
        activeStep === steps.indexOf(ENTER_CLIENT_LABEL) &&
        data.clientId !== -1) ||
      (formData.showEmail &&
        activeStep === steps.indexOf(CONFIRM_EMAIL_LABEL) &&
        data.email !== "") ||
      (formData.showPassword &&
        activeStep === steps.indexOf(ENTER_SECURE_PASSWORD_LABEL) &&
        isValidPassword)
    );
  };

  const attempUpdate = () => {
    setAlert(noAlert);
    attemptUpdate(formData)
      .then(() => {
        navigator("/landing");
      })
      .catch((e: Error) => {
        setAlert({
          msg: e.message || "Unable to update email/password",
          severity: "error",
        });
      });
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    attempUpdate();
  };

  const handleNext = () => {
    if (isValidFormData(formData)) {
      if (activeStep === steps.length - 1) {
        attempUpdate();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigator("/password");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <form onSubmit={submitForm}>
      <Stack spacing={3}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back
        </Button>

        <Stack>
          <Typography variant="caption">Successfully logged in</Typography>
          <Typography variant="h5">Just a few more steps</Typography>
        </Stack>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Step key={`${index}`}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {formData.showClient &&
          activeStep === steps.indexOf(ENTER_CLIENT_LABEL) && (
            <PromptForClientEntry
              onChange={(clientId: string) =>
                updateFormData("clientId", clientId)
              }
            />
          )}
        {formData.showEmail &&
          activeStep === steps.indexOf(CONFIRM_EMAIL_LABEL) && (
            <PromptForEmailEntry
              formData={formData}
              handleChange={handleChange}
            />
          )}
        {formData.showPassword &&
          activeStep === steps.indexOf(ENTER_SECURE_PASSWORD_LABEL) && (
            <PromptForNewPassword
              onValidPassword={(password: string) => {
                setIsValidPassword(true);
                updateFormData("password", password);
              }}
              onInvalidPassword={(password: string) => {
                setIsValidPassword(false);
                updateFormData("password", password);
              }}
            />
          )}
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={handleNext}
          disabled={!isValidFormData(formData)}
        >
          Continue
        </Button>
        <div hidden={alert.msg === ""}>
          <StyledAlert severity="error" onClose={() => setAlert(noAlert)}>
            {alert.msg}
          </StyledAlert>
        </div>
      </Stack>
    </form>
  );
};
