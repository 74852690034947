import React, { useEffect, useState } from "react";
import {
  TextField,
  AlertColor,
  Button,
  Typography,
  Stack,
  Grid,
} from "@mui/material";

import { FormTitle, StyledAlert } from "../ui/StyledControls";
import { requestPasswordReset } from "../proxies/LoginProxy";
import { loadLassoState } from "../app/storage";
import { RestPasswordRequestData } from "../models/PostRequests";
import { EMPTY_STATE } from "../models/LassoState";

export const ForgotPasswordForm = ({
  navigator,
}: {
  navigator: (url: string) => void;
}) => {
  const [lassoSecrets, setLassoSecrets] = useState(EMPTY_STATE);
  const noAlert = { msg: "", severity: "info" as AlertColor };
  const [alert, setAlert] = useState(noAlert);
  const [formData, setFormData] = useState({} as RestPasswordRequestData);

  const clearAlert = () => setAlert(noAlert);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    clearAlert();

    requestPasswordReset(formData)
      .then((msg) => setAlert({ msg, severity: "info" }))
      .catch((e: Error) =>
        setAlert({ msg: e.message || e.toString(), severity: "error" })
      );
  };

  useEffect(() => {
    const getState = async () => {
      const state = await loadLassoState();
      setLassoSecrets(state);
      setFormData({
        userName: state.userName,
      })
    };
    // eslint-disable-next-line no-void
    void getState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={submitForm}>
      <Stack spacing={3}>
        <FormTitle>Forgot your password?</FormTitle>
        <TextField
          disabled
          fullWidth
          label="Username or Email"
          name="Username"
          size="small"
          variant="outlined"
          value={formData.userName}
          InputLabelProps={{ shrink: true }}
        />
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Press <b>Request Reset Link</b> to request an email with a password
          reset link.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          You may need to check your spam folder.
        </Typography>
        <input hidden name="userName" value={formData.userName} />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button fullWidth type="submit" variant="contained">
              Request Reset Link
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              color="success"
              onClick={() => {
                navigator("/");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <div hidden={alert.msg === ""}>
          <StyledAlert severity={alert.severity} onClose={clearAlert}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: alert.msg }} />
          </StyledAlert>
        </div>
      </Stack>
    </form>
  );
};
